.box-attributes-main-container {
  .quantity-field-container {
    text-align: center;
    padding-bottom: 10px;
    .quantity-title {
      font-size: 10px;
      letter-spacing: 1px;
      padding-bottom: 10px;
    }
    .quantity-button-field {
      padding-bottom: 10px;
    }
  }
  .card-selection-title {
    font-size: 10px;
    letter-spacing: 1px;
    padding-top: 10px;
  }

  .loading-icon {
    margin: auto;
  }

  .box-selection-container {
    padding-bottom: 10px;
    .box-selection-title {
      font-size: 10px;
      letter-spacing: 1px;
      padding-bottom: 10px;
    }
    .box-selection-summary {
      font-size: 14px;
      letter-spacing: 0.5px;
      padding-bottom: 20px;
    }
    .box-image-selection {
      display: flex;
      justify-content: space-evenly;
      .box-info {
        height: 211px;
        width: 166px;
        border: 3px solid lightgray;

        .box-image {
          height: 166px;
          width: 160px;
          cursor: pointer;
        }
        .box-title {
          padding: 10px 0;
          letter-spacing: 1px;
          font-size: 10px;
        }
      }
      .box-selected {
        border: 3px solid #5fc3ca;
      }
    }
  }
  .pick-card-container {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border: 2px dashed #ddd;
    cursor: pointer;
    .pick-card-title {
      font-size: 10px;
      font-weight: 700;
    }
  }

  .pick-card-container-selected {
    border: 2px solid #5fc3ca;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    height: 84px;
    cursor: pointer;
    display: inline-flex;
    .selected-card-image {
      flex: 1;
      width: 80px;
      height: 80px;
      img {
        width: 80px;
        height: 80px;
      }
    }
    .selected-card-title-change-text {
      flex: 5;
      padding: 20px;
      text-align: left;

      .selected-card-title {
        padding-bottom: 5px;
      }
      .selected-card-change {
        color: lightgray;
      }
    }
  }
  .cart-button-container {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border: 1px solid black;
    background-color: white;
    width: 100%;
    cursor: pointer;

    .cart-button-title {
      font-size: 10px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 400;
      color: inherit;
      border: none;
      background-color: inherit;
    }
  }
  .more-buttons-container {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;

    .shop-more-button {
      flex: 1;
      text-align: center;
      font-size: 10px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 400;
      color: black;
      padding-top: 20px;
      padding-bottom: 20px;
      background-color: #ffffff;
      margin-right: 10px;
      border: 1px solid black;
    }

    .checkout-button {
      flex: 1;
      text-align: center;
      font-size: 10px;
      text-transform: uppercase;
      font-weight: 400;
      color: black;
      background-color: #ffffff;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-left: 10px;
      border: 1px solid black;
      letter-spacing: 1px;
    }
  }
}

.shop-item-input-wrap {
  display: flex;
  width: 110px;
  align-items: center;
  margin: auto;
  height: 30px;
  border: 1px solid #5fc3ca;
  border-radius: 2px;
}

.shop-item-increase-decrease {
  width: 30%;
  cursor: pointer;
  height: 29px;
  vertical-align: middle;
  padding-top: 6px;
}

.shop-item-increase-decrease:hover {
  background: #5fc3ca;
  color: white;
}

.shop-item-input-wrap input {
  width: 40%;
  border: none;
  text-align: center;
}

.shop-item-input-wrap input:focus {
  border: none;
  outline: none;
}

.box-attributes-main-container .more-buttons-container .shop-more-button:hover {
  background-color: #5fc3ca;
  color: white;
  cursor: pointer;
}

.box-attributes-main-container .more-buttons-container .checkout-button:hover {
  background-color: #5fc3ca;
  color: white;
  cursor: pointer;
}

.box-attributes-main-container .cart-button-container:hover {
  background-color: #5fc3ca;
  color: white;
  cursor: pointer;
}
