.box-detail-main-container {
  max-width: 1180px;
  margin: 0 auto;
  .box-detail-header-container {
    padding-top: 60px;
    padding-left: 60px;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-flex;
    .shop-button {
      padding-right: 20px;
      cursor: pointer;
    }
    .right-chevron {
      padding-right: 20px;
    }
    .box-name {
      padding-right: 20px;
    }
  }
  .box-detail-slider-info {
    display: flex;
    margin-top: 40px;
    .box-detail-slider {
      flex: 2;
      padding-left: 30px;
      text-align: center;
      .box-name {
        font-size: 24px;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
      .box-terms {
        font-size: 14px;
        margin-bottom: 20px;
      }
      .compare-price {
        display: inline-flex;
        .price {
          margin-bottom: 15px;
          font-size: 10px;
          text-align: left;
        }
        .actual-price {
          text-align: left;
          margin-bottom: 15px;
          font-size: 10px;
          color: #5fc3ca;
          padding-left: 5px;
          text-decoration: line-through;
        }
      }

      .box-price {
        font-size: 14px;
        letter-spacing: 1px;
        margin-bottom: 10px;
      }
    }
    .box-detail-info {
      flex: 1;
      text-align: center;
      padding-left: 30px;
    }
  }
}

.loading-icon {
  margin: 10% 50%;
}
