.cart-main-container {
  max-width: 1180px;
  margin: 0 auto;
  font-family: 'BrandonGrotesque Regular';
  .cart-footer-container {
    display: flex;
    flex-direction: row-reverse;
    .cart-checkout-subtotal-container {
      .cart-subtotal {
        display: flex;
        text-align: center;
        .subtotal-text {
          font-size: 10px;
          text-transform: uppercase;
          font-weight: 300;
          letter-spacing: 1px;
          padding: 10px;
        }
        .subtotal-value {
          font-size: 20px;
          font-weight: 400;
          margin-bottom: 10px;
        }
      }
      .cart-checkout-info {
        margin-bottom: 20px;
        font-weight: 300;
        font-size: 10px;
        text-align: center;
      }
      .cart-checkout-container {
        text-transform: uppercase;
        text-align: center;
        padding: 16px 48px;
        background-color: white;
        border: 1px solid black;
        letter-spacing: 1px;
        font-weight: 300px;
        font-size: 10px;
        color: black;
        width: 200px;
        cursor: pointer;
      }
      .cart-checkout-container:hover {
        background-color: #5fc3ca;
        color: white;
      }
    }
    margin-bottom: 50px;
  }
  .empty-cart {
    padding-top: 280px;
    margin: auto;
    width: 240px;
    .empty-cart-title {
      text-align: center;
      font-size: 24px;
      letter-spacing: 1px;
      padding-bottom: 10px;
    }
    .empty-cart-subtitle {
      text-align: center;
      font-size: 14px;
      padding-bottom: 10px;
    }
    .empty-cart-shop {
      padding: 15px 0px;
      font-size: 10px;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 1px;
      background-color: white;
      color: black;
      border: 1px solid black;
      margin-top: 10px;
      cursor: pointer;
    }
    .empty-cart-shop:hover {
      background-color: #5fc3ca;
      color: white;
    }
  }
}
