@media only screen and (max-width: 90%) {
}

@media only screen and (max-width: 1200px) {
}

/* #Tablet (Landscape)
================================================== */
@media only screen and (max-width: 1200px) {
  .card-modal-container {
    width: 720px;
  }

  .card-modal-container .card-image-component {
    width: 180px;
    height: 180px;
    padding: 5px;
    margin-bottom: 30px;
    cursor: pointer;
  }

  .card-modal-container .card-image-component .card-image {
    padding: 5px;
    width: 180px;
    height: 180px;
    position: relative;
  }

  .card-modal-header .card-modal-summary {
    font-size: 10px;
    text-align: center;
    padding: 10px 0;
    width: 65%;
    margin: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
}
/* #Tablet (Portrait)
================================================== */
@media only screen and (min-width: 768px) and (max-width: 959px) {
  .card-modal-header .card-modal-summary {
    font-size: 10px;
    text-align: center;
    padding: 10px 0;
    width: 65%;
    margin: auto;
  }
}

/*  #Mobile (Portrait)
================================================== */

/* Note: Design for a width of 320px */

@media only screen and (max-width: 767px) {
  .card-modal-container {
    width: 300px;
    justify-content: space-between;
  }

  .card-modal-container .card-image-component {
    width: 100px;
    height: 100px;
    padding: 0px;
    margin-bottom: 30px;
    cursor: pointer;
    justify-content: space-between;
    text-align: center;
  }

  .card-modal-container .card-image-component .card-footer-text {
    font-size: 12px;
    letter-spacing: 0;
    text-align: center;
  }
  .card-modal-container .card-image-component .card-image {
    padding: 5px;
    width: 100px;
    height: 100px;
    position: relative;
  }
}

/* #Mobile (Landscape)
================================================== */

/* Note: Design for a width of 480px */

@media only screen and (min-width: 480px) and (max-width: 767px) {
}
/* iPhone 5 (Portrait) */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
}

/* iPhone 6 Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
}

/* iPhone 6 landscape */
@media only screen and (min-device-width: 641px) and (max-device-width: 667px) and (orientation: landscape) {
}

/* iPhone 6+ Portrait */
@media only screen and (min-device-width: 412px) and (max-device-width: 736px) and (orientation: portrait) {
}

/* iPhone 6+ landscape */
@media only screen and (min-device-width: 668px) and (max-device-width: 736px) and (orientation: landscape) {
}
