@media only screen and (max-width: 90%) {
}

@media only screen and (max-width: 1200px) {
  .box-detail-main-container {
    width: 90%;
    margin: auto;
  }
  .box-detail-main-container .box-detail-slider-info .box-detail-slider {
    padding: 0;
  }

  .loading-icon img {
    display: block;
    margin: 80px auto 0px auto;
  }

  .loading-icon {
    margin: unset;
  }
}

/* #Tablet (Landscape)
================================================== */
@media only screen and (max-width: 1200px) {
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
}
/* #Tablet (Portrait)
================================================== */
@media only screen and (min-width: 768px) and (max-width: 959px) {
  .box-detail-main-container {
    width: 90%;
    margin: auto;
  }
  .box-detail-main-container .box-detail-slider-info .box-detail-slider {
    padding: 0;
  }

  .loading-icon img {
    display: block;
    margin: 80px auto 0px auto;
  }

  .loading-icon {
    margin: unset;
  }
}

/*  #Mobile (Portrait)
================================================== */

/* Note: Design for a width of 320px */

@media only screen and (max-width: 767px) {
  .box-detail-main-container .box-detail-slider-info {
    display: block;
    width: 320px;
    margin: auto;
  }
  .box-detail-main-container {
    width: 100%;
    margin: auto;
  }
  .box-detail-main-container .box-detail-slider-info > div {
    padding-left: 0px !important;
  }

  .box-detail-main-container .box-detail-slider-info .box-selection-container {
    margin-top: 20px;
  }

  .box-detail-main-container {
    margin-top: 30px;
  }

  .loading-icon img {
    display: block;
    margin: 80px auto 0px auto;
  }

  .loading-icon {
    margin: unset;
  }
}

/* #Mobile (Landscape)
================================================== */

/* Note: Design for a width of 480px */

@media only screen and (min-width: 480px) and (max-width: 767px) {
}
/* iPhone 5 (Portrait) */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
}

/* iPhone 6 Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
}

/* iPhone 6 landscape */
@media only screen and (min-device-width: 641px) and (max-device-width: 667px) and (orientation: landscape) {
}

/* iPhone 6+ Portrait */
@media only screen and (min-device-width: 412px) and (max-device-width: 736px) and (orientation: portrait) {
}

/* iPhone 6+ landscape */
@media only screen and (min-device-width: 668px) and (max-device-width: 736px) and (orientation: landscape) {
}
