@import '~react-image-gallery/styles/scss/image-gallery.scss';

.image-slider-container {
  z-index: 0 !important;
}

.image-gallery-custom-right-nav {
  z-index: 2;
}

.image-gallery-icon {
  color: $ig-white;
  transition: all 0.2s ease-out;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 1 !important;
  filter: drop-shadow(0 2px 2px lighten($ig-black, 10%));

  @media (min-width: $ig-small-screen) {
    // Don't hover on screens smaller than small
    &:hover {
      color: $ig-blue;
      .image-gallery-svg {
        transform: scale(1.1);
      }
    }
  }

  &:focus {
    // a11y support
    outline: 2px solid $ig-blue;
  }
}

.image-gallery-thumbnail {
  border: 4px #5fc3ca !important;
}

.image-gallery-thumbnail.active {
  border: 4px #5fc3ca !important;
}

.image-gallery-thumbnail :focus :hover {
  border: 4px #5fc3ca !important;
}

.image-gallery-icon {
  &:hover {
    color: #5fceca;
  }
}
