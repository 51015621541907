.box-msg-data {
  width: 100%;
}

.change-card-button {
  text-align: center;
}

.card-image .card-image-selected {
  width: 350px;
  height: 350px;
}

.box-msg-data .box-msg-to-from {
  display: flex;
  justify-content: space-between;
}

.box-msg-data .card-label {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  line-height: 1.6;
  color: #000;
}

.box-msg-data .box-msg-to-from > div {
  width: 48%;
  display: block;
}
.box-msg-data .box-msg-to-from input.box-msg-input {
  border: 1px solid #cccccc;
  border-radius: 0;
  outline: none;
  width: 100%;
  max-width: 100%;
  display: block;
  margin: 8px 0 1em;
  background-color: transparent;
  padding: 8px 10px;
  line-height: 1.42;
  font-size: 1em;
  color: #000;
  font-weight: normal;
  font-family: 'BrandonGrotesque Regular';
}

input {
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  text-align: start;
  -webkit-rtl-ordering: logical;
  cursor: text;
  -writing-mode: horizontal-tb !important;
  text-rendering: auto;
  font-family: 'BrandonGrotesque Regular';
}

.box-msg-data .box-msg-to-from input::placeholder,
.box-msg-data .box-msg-description textarea::placeholder {
  color: #999999;
  letter-spacing: 1px;
  font-family: 'BrandonGrotesque Regular';
}

.box-msg-data .box-msg-description textarea:focus {
  outline: none !important;
  outline-offset: unset !important;
}

.box-msg-data .box-msg-description textarea {
  display: block;
  margin: 10px 0 15px 0;
  margin-top: 10px;
  margin-right: 0px;
  margin-bottom: 15px;
  margin-left: 0px;
  padding: 15px;
  width: 100%;
  height: auto;
  font-size: 14px;
  font-weight: normal;
  color: #000;
  border: solid 1px #ccc;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
  background-color: transparent;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  text-align: start;
  text-rendering: auto;
  -webkit-rtl-ordering: logical;
  -webkit-writing-mode: horizontal-tb !important;
  flex-direction: column;
  resize: auto;
  cursor: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  font-family: 'BrandonGrotesque Regular';
  resize: none;
}

.box-msg-data .box-msg-characters {
  margin-top: -10px;
  color: black;
  text-align: right;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.6;
  font-family: 'BrandonGrotesque Regular';
}

.box-msg-data .box-msg-checkbox-container {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -moz-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start;
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  line-height: 1.6;
  color: #000;
  padding-top: 10px;
  font-family: 'BrandonGrotesque Regular';
}

.box-msg-data .box-msg-checkbox-container input {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  position: relative;
  display: inline-block;
  margin: 0 7px 0 0;
  width: 18px;
  height: 18px;
  outline: 0;
  color: #fff;
  border-radius: 3px;
  border: solid 2px #000;
  // -webkit-appearance: none;
  background: transparent;
  vertical-align: middle;
  cursor: pointer;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  line-height: 1.42;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
  font-family: 'BrandonGrotesque Regular';
}

.box-msg-data .box-msg-checkbox-container input::before {
  position: absolute;
  top: 50%;
  left: 50%;
  content: '\f00c';
  font-weight: 900;
  font-size: 10px;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.box-msg-data .box-msg-checkbox-container input.checked::before {
  opacity: 1 !important;
}

.box-msg-data .box-msg-checkbox-container span {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -moz-box-flex: 1;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: inline-block;
  margin: 0;
  line-height: 18px;
  font-weight: normal;
  vertical-align: middle;
  font-size: 14px;
  text-align: left;
  font-weight: 600;
  font-family: 'BrandonGrotesque Regular';
}
