.cart-header-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  .cart-title {
    margin-top: 100px;
    font-size: 30px;
  }
  .cart-column-headers {
    display: flex;
    margin-bottom: 10px;
    .cart-column-item {
      flex: 2;
      padding: 10px;
      text-align: center;
    }
    .cart-column {
      flex: 1;
      padding: 10px;
      text-align: center;
    }
  }
}
