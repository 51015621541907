.info-modal-container {
  width: 346px;
  height: 300px;
  background-image: url("https://cdn.shopify.com/s/files/1/0445/1313/2702/files/pop_up.png?v=1603414518");
  background-size: cover;
  padding: 30px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 18px;
  .info-modal-image {
    width: 346px;
    height: 300px;
  }
  .info-modal-title-container{
    margin-top: 40px;
    padding: 0 36px;
    line-height: 24px;
  }
  .info-modal-title {
    display: block;
    padding: 0px 5px 0 5px;
    font-size: 20px;
    color: #000;
    text-align: left;
  }
  .info-modal-info {
    display: block;
    padding: 20px 5px;
    font-size: 14px;
    color: #000;
    text-align: left;
  }
  .info-modal-button-container{
    margin-top: 85px;
    color: white;
    height: 50px;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    .info-button{
      padding: 11px;
      cursor: pointer;
    }
  }
  .info-modal-button {
    display: block;
    padding: 20px 5px;
    border-top: solid 1px lightgray;
    text-align: right;
    .info-modal-button-text {
      cursor: pointer;
      padding: 18px 20px;
      background-color: #5fc3ca;
      color: white;
      border: none;
      font-size: 10px;
      letter-spacing: 1px;
    }
  }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba($color: #000, $alpha: 0.4) !important;
}
