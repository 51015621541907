.shop-main-container {
  max-width: 1180px;
  margin: 0px auto;
  .shop-header-container {
    margin-top: 70px;
    font-family: 'Jane Austen';
    margin-bottom: 10px;
    font-size: 30px;
    padding-left: 30px;
  }
  .shop-header-subtitle {
    font-family: 'BrandonGrotesque Regular';
    margin-bottom: 10px;
    font-size: 15px;
    padding-left: 30px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }
  .shop-header-subtitle-summary {
    font-family: 'BrandonGrotesque Regular';
    margin-bottom: 50px;
    font-size: 15px;
    padding-left: 30px;
  }
  .shop-items-container {
    display: flex;
    flex-wrap: wrap;
  }
}
