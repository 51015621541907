.header {
  font-size: 18px;
  font-weight: 400;
  color: #3984c9;
  text-align: center; }

.main-title {
  padding-bottom: 10px; }

@font-face {
  font-family: 'BrandonGrotesque Regular';
  src: local("BrandonGrotesque Regular"), url(/static/media/BrandonGrotesque-Regular.af1f9cf6.ttf) format("truetype"); }

@font-face {
  font-family: 'Jane Austen';
  src: local("Jane Austen"), url("/static/media/JaneAusten V2.9d1f1118.ttf") format("truetype"); }

body {
  font-family: 'BrandonGrotesque Regular';
  margin: 0px; }

#root {
  scroll-behavior: smooth; }

html {
  scroll-behavior: smooth; }

.shop-main-container {
  max-width: 1180px;
  margin: 0px auto; }
  .shop-main-container .shop-header-container {
    margin-top: 70px;
    font-family: 'Jane Austen';
    margin-bottom: 10px;
    font-size: 30px;
    padding-left: 30px; }
  .shop-main-container .shop-header-subtitle {
    font-family: 'BrandonGrotesque Regular';
    margin-bottom: 10px;
    font-size: 15px;
    padding-left: 30px;
    text-transform: uppercase;
    letter-spacing: 1.5px; }
  .shop-main-container .shop-header-subtitle-summary {
    font-family: 'BrandonGrotesque Regular';
    margin-bottom: 50px;
    font-size: 15px;
    padding-left: 30px; }
  .shop-main-container .shop-items-container {
    display: flex;
    flex-wrap: wrap; }

.container {
  position: relative;
  width: 257px;
  height: 257px;
  cursor: pointer; }

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease; }

.container:hover .overlay {
  display: block; }

.product-container {
  padding-left: 30px;
  margin-bottom: 30px; }

img.product-image {
  position: absolute;
  width: 257px;
  height: 257px;
  left: 0; }

.product-details {
  padding-top: 15px;
  padding-bottom: 15px; }

.button .text {
  padding: 12px 40px;
  text-align: center;
  color: black;
  border: solid 1px black;
  background-color: white;
  z-index: 1;
  text-decoration: none; }

.container:hover .button {
  opacity: 1; }

.title {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 10px;
  text-align: left;
  letter-spacing: 1px; }

.price {
  margin-bottom: 15px;
  font-size: 10px;
  text-align: left; }

.actual-price {
  text-align: left;
  margin-bottom: 15px;
  font-size: 10px;
  color: #5fc3ca;
  padding-left: 5px;
  text-decoration: line-through; }

.compare-price {
  display: inline-flex; }

@media only screen and (max-width: 90%) {
}

@media only screen and (max-width: 1200px) {
}

/* #Tablet (Landscape)
================================================== */
@media only screen and (max-width: 1200px) {
  .shop-main-container{
    max-width: 920px;
    margin: auto;
  }

  .shop-main-container .shop-items-container {
    display: flex;
    width: 820px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
  }

  .shop-main-container .shop-header-subtitle{
    max-width: 90%;
    width: 820px;
    padding: 0;
    margin: 0 auto 10px auto;
  }

  .shop-main-container .shop-header-subtitle-summary{
    max-width: 90%;
    width: 820px;
    padding: 0;
    margin: 0 auto 30px auto;
  }

  .shop-main-container .shop-header-container{
    max-width: 90%;
    width: 820px;
    padding: 0;
    margin: 70px auto 20px auto;
  }

  .shop-main-container .shop-items-container .product-container{
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
}
/* #Tablet (Portrait)
================================================== */
@media only screen and (min-width: 768px) and (max-width: 959px) {
  .container {
    position: relative;
    width: 220px;
    height: 220px;
    cursor: pointer;
  }

  
  img.product-image {
    position: absolute;
    width: 220px;
    height: 220px;
    left: 0;
  }

  .shop-main-container{
    max-width: 920px;
    margin: auto;
  }

  .shop-main-container .shop-items-container .product-container{
    padding: 0;
  }
  
  .shop-main-container .shop-header-subtitle{
    max-width: 90%;
    width: 820px;
    padding: 0;
    margin: 0 auto 10px auto;
  }

  .shop-main-container .shop-header-subtitle-summary{
    max-width: 90%;
    width: 820px;
    padding: 0;
    margin: 0 auto 30px auto;
  }

  .shop-main-container .shop-items-container {
    display: flex;
    max-width: 90%;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
  }

  
  .shop-main-container .shop-header-container{
    max-width: 90%;
    padding: 0;
    margin: 70px auto 20px auto;
  }

}

/*  #Mobile (Portrait)
================================================== */

/* Note: Design for a width of 320px */

@media only screen and (max-width: 767px) {
  .container {
    position: relative;
    width: 150px;
    height: 150px;
    cursor: pointer;
  }

  img.product-image {
    position: absolute;
    width: 150px;
    height: 150px;
    left: 0;
  }

  .product-container {
    padding-left: 0px;
    margin-bottom: 0px;
  }

  .shop-main-container .shop-items-container {
    display: flex;
    width: 320px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
  }

  .shop-main-container .shop-header-container{
    max-width: unset;
    width: 320px;
    margin: 50px auto 15px auto;
  }

  .shop-main-container .shop-header-subtitle{
    max-width: unset;
    width: 320px;
    padding: 0;
    margin: 0 auto 10px auto;
  }

  .shop-main-container .shop-header-subtitle-summary{
    max-width: unset;
    width: 320px;
    padding: 0;
    margin: 0 auto 30px auto;
  }
}

/* #Mobile (Landscape)
================================================== */

/* Note: Design for a width of 480px */

@media only screen and (min-width: 480px) and (max-width: 767px) {
}
/* iPhone 5 (Portrait) */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
}

/* iPhone 6 Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
}

/* iPhone 6 landscape */
@media only screen and (min-device-width: 641px) and (max-device-width: 667px) and (orientation: landscape) {
}

/* iPhone 6+ Portrait */
@media only screen and (min-device-width: 412px) and (max-device-width: 736px) and (orientation: portrait) {
}

/* iPhone 6+ landscape */
@media only screen and (min-device-width: 668px) and (max-device-width: 736px) and (orientation: landscape) {
}

.cart-main-container {
  max-width: 1180px;
  margin: 0 auto;
  font-family: 'BrandonGrotesque Regular'; }
  .cart-main-container .cart-footer-container {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 50px; }
    .cart-main-container .cart-footer-container .cart-checkout-subtotal-container .cart-subtotal {
      display: flex;
      text-align: center; }
      .cart-main-container .cart-footer-container .cart-checkout-subtotal-container .cart-subtotal .subtotal-text {
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 300;
        letter-spacing: 1px;
        padding: 10px; }
      .cart-main-container .cart-footer-container .cart-checkout-subtotal-container .cart-subtotal .subtotal-value {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 10px; }
    .cart-main-container .cart-footer-container .cart-checkout-subtotal-container .cart-checkout-info {
      margin-bottom: 20px;
      font-weight: 300;
      font-size: 10px;
      text-align: center; }
    .cart-main-container .cart-footer-container .cart-checkout-subtotal-container .cart-checkout-container {
      text-transform: uppercase;
      text-align: center;
      padding: 16px 48px;
      background-color: white;
      border: 1px solid black;
      letter-spacing: 1px;
      font-weight: 300px;
      font-size: 10px;
      color: black;
      width: 200px;
      cursor: pointer; }
    .cart-main-container .cart-footer-container .cart-checkout-subtotal-container .cart-checkout-container:hover {
      background-color: #5fc3ca;
      color: white; }
  .cart-main-container .empty-cart {
    padding-top: 280px;
    margin: auto;
    width: 240px; }
    .cart-main-container .empty-cart .empty-cart-title {
      text-align: center;
      font-size: 24px;
      letter-spacing: 1px;
      padding-bottom: 10px; }
    .cart-main-container .empty-cart .empty-cart-subtitle {
      text-align: center;
      font-size: 14px;
      padding-bottom: 10px; }
    .cart-main-container .empty-cart .empty-cart-shop {
      padding: 15px 0px;
      font-size: 10px;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 1px;
      background-color: white;
      color: black;
      border: 1px solid black;
      margin-top: 10px;
      cursor: pointer; }
    .cart-main-container .empty-cart .empty-cart-shop:hover {
      background-color: #5fc3ca;
      color: white; }

@media only screen and (max-width: 90%) {
}

@media only screen and (max-width: 1200px) {
    .cart-main-container{
        width: 95%;
    }
}

/* #Tablet (Landscape)
================================================== */
@media only screen and (max-width: 1200px) {
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
}
/* #Tablet (Portrait)
================================================== */
@media only screen and (min-width: 768px) and (max-width: 959px) {
}

/*  #Mobile (Portrait)
================================================== */

/* Note: Design for a width of 320px */

@media only screen and (max-width: 767px) {
    .cart-main-container .empty-cart{
        padding-top: 180px;
    }
}

/* #Mobile (Landscape)
================================================== */

/* Note: Design for a width of 480px */

@media only screen and (min-width: 480px) and (max-width: 767px) {
}
/* iPhone 5 (Portrait) */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
}

/* iPhone 6 Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
}

/* iPhone 6 landscape */
@media only screen and (min-device-width: 641px) and (max-device-width: 667px) and (orientation: landscape) {
}

/* iPhone 6+ Portrait */
@media only screen and (min-device-width: 412px) and (max-device-width: 736px) and (orientation: portrait) {
}

/* iPhone 6+ landscape */
@media only screen and (min-device-width: 668px) and (max-device-width: 736px) and (orientation: landscape) {
}

.cart-header-container {
  width: 100%;
  margin: 0 auto;
  position: relative; }
  .cart-header-container .cart-title {
    margin-top: 100px;
    font-size: 30px; }
  .cart-header-container .cart-column-headers {
    display: flex;
    margin-bottom: 10px; }
    .cart-header-container .cart-column-headers .cart-column-item {
      flex: 2 1;
      padding: 10px;
      text-align: center; }
    .cart-header-container .cart-column-headers .cart-column {
      flex: 1 1;
      padding: 10px;
      text-align: center; }

@media only screen and (max-width: 90%) {
}

@media only screen and (max-width: 1200px) {
}

/* #Tablet (Landscape)
================================================== */
@media only screen and (max-width: 1200px) {
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
}
/* #Tablet (Portrait)
================================================== */
@media only screen and (min-width: 768px) and (max-width: 959px) {
}

/*  #Mobile (Portrait)
================================================== */

/* Note: Design for a width of 320px */

@media only screen and (max-width: 767px) {
}

/* #Mobile (Landscape)
================================================== */

/* Note: Design for a width of 480px */

@media only screen and (min-width: 480px) and (max-width: 767px) {
}
/* iPhone 5 (Portrait) */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
}

/* iPhone 6 Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
}

/* iPhone 6 landscape */
@media only screen and (min-device-width: 641px) and (max-device-width: 667px) and (orientation: landscape) {
}

/* iPhone 6+ Portrait */
@media only screen and (min-device-width: 412px) and (max-device-width: 736px) and (orientation: portrait) {
}

/* iPhone 6+ landscape */
@media only screen and (min-device-width: 668px) and (max-device-width: 736px) and (orientation: landscape) {
}

.cart-item-container {
  display: flex;
  position: relative;
  padding-top: 30px;
  margin-top: 30px;
  padding-bottom: 20px; }
  .cart-item-container .cart-item-image-contents {
    flex: 2 1;
    display: flex;
    position: relative; }
    .cart-item-container .cart-item-image-contents .item-image {
      flex: 2 1;
      vertical-align: middle; }
      .cart-item-container .cart-item-image-contents .item-image .box-item-image {
        max-width: 100px;
        max-height: 100px;
        margin-top: 40px; }
    .cart-item-container .cart-item-image-contents .cart-item-contents {
      flex: 3 1;
      vertical-align: middle;
      display: table-cell;
      float: none; }
      .cart-item-container .cart-item-image-contents .cart-item-contents .item-title-container .item-title {
        font-size: 24px;
        text-transform: uppercase;
        padding-bottom: 5px; }
      .cart-item-container .cart-item-image-contents .cart-item-contents .item-title-container .item-title-subtext {
        font-size: 10px;
        font-weight: 300;
        padding-bottom: 20px; }
      .cart-item-container .cart-item-image-contents .cart-item-contents .item-card {
        font-weight: 200; }
      .cart-item-container .cart-item-image-contents .cart-item-contents .item-contents {
        font-weight: 200; }
      .cart-item-container .cart-item-image-contents .cart-item-contents .item-remove {
        font-size: 9px;
        text-transform: uppercase;
        color: grey;
        padding-top: 10px;
        cursor: pointer; }
  .cart-item-container .cart-item-price {
    flex: 1 1;
    text-align: center;
    margin: auto 0px; }
  .cart-item-container .cart-item-quantity {
    flex: 1 1;
    text-align: center;
    margin: auto 0px; }
    .cart-item-container .cart-item-quantity .loading-icon {
      width: 110px;
      align-items: center;
      margin: auto;
      height: 30px; }
  .cart-item-container .cart-item-subtotal {
    flex: 1 1;
    text-align: center;
    margin: auto 0px; }

.cart-item-input-wrap {
  display: flex;
  width: 110px;
  align-items: center;
  margin: auto;
  height: 30px;
  border: 1px solid black;
  border-radius: 2px; }

.cart-item-increase-decrease {
  width: 30%;
  cursor: pointer;
  height: 29px;
  vertical-align: middle;
  padding-top: 6px; }

.cart-item-increase-decrease:hover {
  background: #5fc3ca;
  color: white;
  color: white; }

.cart-item-input-wrap input {
  width: 40%;
  border: none;
  text-align: center; }

.cart-item-input-wrap input:focus {
  border: none;
  outline: none; }

@media only screen and (max-width: 90%) {
}

@media only screen and (max-width: 1200px) {
}

/* #Tablet (Landscape)
================================================== */
@media only screen and (max-width: 1200px) {
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
}
/* #Tablet (Portrait)
================================================== */
@media only screen and (min-width: 768px) and (max-width: 959px) {
}

/*  #Mobile (Portrait)
================================================== */

/* Note: Design for a width of 320px */

@media only screen and (max-width: 767px) {
    .mobile .cart-item-details-wrap{
        display: flex;
        justify-content: space-between;
        padding: 10px;
    }

    .mobile .cart-item-details-wrap .cart-item-price-container,
    .mobile .cart-item-details-wrap .cart-item-subtotal-container{
        width: 60px;
    }
    .mobile .cart-item-details-wrap .cart-item-price-container > div,
    .mobile .cart-item-details-wrap .cart-item-subtotal-container > div{
        text-align: left;
    }

    .mobile .cart-item-details-wrap .primary{
        height: 20px;
    }

    .mobile .cart-item-details-wrap .secondary{
        height: 30px;
        vertical-align: middle;
        margin: 5px auto;
    }
    
    .mobile .cart-item-details-wrap .cart-item--value{
        margin-top: 10px;
    }

    .cart-item-container.mobile .cart-item-image-content-wrap{
        padding: 10px;
    }

    .cart-main-container .cart-header-container .cart-title{
        padding: 20px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .cart-item-container{
        margin-top: 0px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    
}

/* #Mobile (Landscape)
================================================== */

/* Note: Design for a width of 480px */

@media only screen and (min-width: 480px) and (max-width: 767px) {
}
/* iPhone 5 (Portrait) */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
}

/* iPhone 6 Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
}

/* iPhone 6 landscape */
@media only screen and (min-device-width: 641px) and (max-device-width: 667px) and (orientation: landscape) {
}

/* iPhone 6+ Portrait */
@media only screen and (min-device-width: 412px) and (max-device-width: 736px) and (orientation: portrait) {
}

/* iPhone 6+ landscape */
@media only screen and (min-device-width: 668px) and (max-device-width: 736px) and (orientation: landscape) {
}

.box-detail-main-container {
  max-width: 1180px;
  margin: 0 auto; }
  .box-detail-main-container .box-detail-header-container {
    padding-top: 60px;
    padding-left: 60px;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-flex; }
    .box-detail-main-container .box-detail-header-container .shop-button {
      padding-right: 20px;
      cursor: pointer; }
    .box-detail-main-container .box-detail-header-container .right-chevron {
      padding-right: 20px; }
    .box-detail-main-container .box-detail-header-container .box-name {
      padding-right: 20px; }
  .box-detail-main-container .box-detail-slider-info {
    display: flex;
    margin-top: 40px; }
    .box-detail-main-container .box-detail-slider-info .box-detail-slider {
      flex: 2 1;
      padding-left: 30px;
      text-align: center; }
      .box-detail-main-container .box-detail-slider-info .box-detail-slider .box-name {
        font-size: 24px;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 10px; }
      .box-detail-main-container .box-detail-slider-info .box-detail-slider .box-terms {
        font-size: 14px;
        margin-bottom: 20px; }
      .box-detail-main-container .box-detail-slider-info .box-detail-slider .compare-price {
        display: inline-flex; }
        .box-detail-main-container .box-detail-slider-info .box-detail-slider .compare-price .price {
          margin-bottom: 15px;
          font-size: 10px;
          text-align: left; }
        .box-detail-main-container .box-detail-slider-info .box-detail-slider .compare-price .actual-price {
          text-align: left;
          margin-bottom: 15px;
          font-size: 10px;
          color: #5fc3ca;
          padding-left: 5px;
          text-decoration: line-through; }
      .box-detail-main-container .box-detail-slider-info .box-detail-slider .box-price {
        font-size: 14px;
        letter-spacing: 1px;
        margin-bottom: 10px; }
    .box-detail-main-container .box-detail-slider-info .box-detail-info {
      flex: 1 1;
      text-align: center;
      padding-left: 30px; }

.loading-icon {
  margin: 10% 50%; }

@media only screen and (max-width: 90%) {
}

@media only screen and (max-width: 1200px) {
  .box-detail-main-container {
    width: 90%;
    margin: auto;
  }
  .box-detail-main-container .box-detail-slider-info .box-detail-slider {
    padding: 0;
  }

  .loading-icon img {
    display: block;
    margin: 80px auto 0px auto;
  }

  .loading-icon {
    margin: unset;
  }
}

/* #Tablet (Landscape)
================================================== */
@media only screen and (max-width: 1200px) {
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
}
/* #Tablet (Portrait)
================================================== */
@media only screen and (min-width: 768px) and (max-width: 959px) {
  .box-detail-main-container {
    width: 90%;
    margin: auto;
  }
  .box-detail-main-container .box-detail-slider-info .box-detail-slider {
    padding: 0;
  }

  .loading-icon img {
    display: block;
    margin: 80px auto 0px auto;
  }

  .loading-icon {
    margin: unset;
  }
}

/*  #Mobile (Portrait)
================================================== */

/* Note: Design for a width of 320px */

@media only screen and (max-width: 767px) {
  .box-detail-main-container .box-detail-slider-info {
    display: block;
    width: 320px;
    margin: auto;
  }
  .box-detail-main-container {
    width: 100%;
    margin: auto;
  }
  .box-detail-main-container .box-detail-slider-info > div {
    padding-left: 0px !important;
  }

  .box-detail-main-container .box-detail-slider-info .box-selection-container {
    margin-top: 20px;
  }

  .box-detail-main-container {
    margin-top: 30px;
  }

  .loading-icon img {
    display: block;
    margin: 80px auto 0px auto;
  }

  .loading-icon {
    margin: unset;
  }
}

/* #Mobile (Landscape)
================================================== */

/* Note: Design for a width of 480px */

@media only screen and (min-width: 480px) and (max-width: 767px) {
}
/* iPhone 5 (Portrait) */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
}

/* iPhone 6 Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
}

/* iPhone 6 landscape */
@media only screen and (min-device-width: 641px) and (max-device-width: 667px) and (orientation: landscape) {
}

/* iPhone 6+ Portrait */
@media only screen and (min-device-width: 412px) and (max-device-width: 736px) and (orientation: portrait) {
}

/* iPhone 6+ landscape */
@media only screen and (min-device-width: 668px) and (max-device-width: 736px) and (orientation: landscape) {
}

.image-gallery-icon {
  color: #fff;
  transition: all .2s ease-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  -webkit-filter: drop-shadow(0 2px 2px #1a1a1a);
          filter: drop-shadow(0 2px 2px #1a1a1a); }
  @media (min-width: 768px) {
    .image-gallery-icon:hover {
      color: #337ab7; }
      .image-gallery-icon:hover .image-gallery-svg {
        transform: scale(1.1); } }
  .image-gallery-icon:focus {
    outline: 2px solid #337ab7; }

.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none; }

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 20px; }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 36px;
    width: 36px; }
  @media (max-width: 768px) {
    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
      padding: 15px; }
      .image-gallery-fullscreen-button .image-gallery-svg,
      .image-gallery-play-button .image-gallery-svg {
        height: 24px;
        width: 24px; } }
  @media (max-width: 480px) {
    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
      padding: 10px; }
      .image-gallery-fullscreen-button .image-gallery-svg,
      .image-gallery-play-button .image-gallery-svg {
        height: 16px;
        width: 16px; } }

.image-gallery-fullscreen-button {
  right: 0; }

.image-gallery-play-button {
  left: 0; }

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  transform: translateY(-50%); }
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 120px;
    width: 60px; }
  @media (max-width: 768px) {
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
      height: 72px;
      width: 36px; } }
  @media (max-width: 480px) {
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
      height: 48px;
      width: 24px; } }
  .image-gallery-left-nav[disabled],
  .image-gallery-right-nav[disabled] {
    cursor: disabled;
    opacity: .6;
    pointer-events: none; }

.image-gallery-left-nav {
  left: 0; }

.image-gallery-right-nav {
  right: 0; }

.image-gallery {
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative; }
  .image-gallery.fullscreen-modal {
    background: #000;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5; }
    .image-gallery.fullscreen-modal .image-gallery-content {
      top: 50%;
      transform: translateY(-50%); }

.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0; }
  .image-gallery-content.fullscreen {
    background: #000; }
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 80px); }
  .image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image {
    max-height: 100vh; }

.image-gallery-slide-wrapper {
  position: relative; }
  .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
    display: inline-block;
    width: calc(100% - 110px); }
    @media (max-width: 768px) {
      .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
        width: calc(100% - 87px); } }
  .image-gallery-slide-wrapper.image-gallery-rtl {
    direction: rtl; }

.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center; }

.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }
  .image-gallery-slide.center {
    position: relative; }
  .image-gallery-slide .image-gallery-image {
    width: 100%;
    object-fit: contain; }
  .image-gallery-slide .image-gallery-description {
    background: rgba(0, 0, 0, 0.4);
    bottom: 70px;
    color: #fff;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal; }
    @media (max-width: 768px) {
      .image-gallery-slide .image-gallery-description {
        bottom: 45px;
        font-size: .8em;
        padding: 8px 15px; } }

.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4; }
  .image-gallery-bullets .image-gallery-bullets-container {
    margin: 0;
    padding: 0;
    text-align: center; }
  .image-gallery-bullets .image-gallery-bullet {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    box-shadow: 0 1px 0 #1a1a1a;
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    outline: none;
    padding: 5px;
    transition: background .2s ease-out; }
    @media (max-width: 768px) {
      .image-gallery-bullets .image-gallery-bullet {
        margin: 0 3px;
        padding: 3px; } }
    @media (max-width: 480px) {
      .image-gallery-bullets .image-gallery-bullet {
        padding: 2.7px; } }
    .image-gallery-bullets .image-gallery-bullet:focus, .image-gallery-bullets .image-gallery-bullet:hover {
      background: #337ab7;
      transform: scale(1.1); }
    .image-gallery-bullets .image-gallery-bullet.active {
      background: #fff; }

.image-gallery-thumbnails-wrapper {
  position: relative; }
  .image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
    direction: rtl; }
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    display: inline-block;
    vertical-align: top;
    width: 100px; }
    @media (max-width: 768px) {
      .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
        width: 81px; } }
    .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
      height: 100%;
      width: 100%;
      left: 0;
      padding: 0;
      position: absolute;
      top: 0; }
      .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
        display: block;
        margin-right: 0;
        padding: 0; }
        .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
          margin-left: 0;
          margin-top: 2px; }
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    margin: 0 5px; }
    @media (max-width: 768px) {
      .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
        margin: 0 3px; } }

.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0; }
  @media (max-width: 768px) {
    .image-gallery-thumbnails {
      padding: 3px 0; } }
  .image-gallery-thumbnails .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    transition: transform .45s ease-out;
    white-space: nowrap; }

.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border .3s ease-out;
  width: 100px;
  background: transparent;
  padding: 0; }
  @media (max-width: 768px) {
    .image-gallery-thumbnail {
      border: 3px solid transparent;
      width: 81px; } }
  .image-gallery-thumbnail + .image-gallery-thumbnail {
    margin-left: 2px; }
  .image-gallery-thumbnail .image-gallery-thumbnail-inner {
    position: relative; }
  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0; }
  .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
    outline: none;
    border: 4px solid #337ab7; }
    @media (max-width: 768px) {
      .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
        border: 3px solid #337ab7; } }

.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: white;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  text-shadow: 1px 1px 0 black;
  transform: translateY(-50%);
  white-space: normal;
  width: 100%; }
  @media (max-width: 768px) {
    .image-gallery-thumbnail-label {
      font-size: .8em;
      line-height: .8em; } }

.image-gallery-index {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4; }
  @media (max-width: 768px) {
    .image-gallery-index {
      font-size: .8em;
      padding: 5px 10px; } }

.image-slider-container {
  z-index: 0 !important; }

.image-gallery-custom-right-nav {
  z-index: 2; }

.image-gallery-icon {
  color: #fff;
  transition: all 0.2s ease-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 1 !important;
  -webkit-filter: drop-shadow(0 2px 2px #1a1a1a);
          filter: drop-shadow(0 2px 2px #1a1a1a); }
  @media (min-width: 768px) {
    .image-gallery-icon:hover {
      color: #337ab7; }
      .image-gallery-icon:hover .image-gallery-svg {
        transform: scale(1.1); } }
  .image-gallery-icon:focus {
    outline: 2px solid #337ab7; }

.image-gallery-thumbnail {
  border: 4px #5fc3ca !important; }

.image-gallery-thumbnail.active {
  border: 4px #5fc3ca !important; }

.image-gallery-thumbnail :focus :hover {
  border: 4px #5fc3ca !important; }

.image-gallery-icon:hover {
  color: #5fceca; }

.box-info-main-container {
  margin-top: 20px;
  margin-bottom: 20px; }
  .box-info-main-container .description-button-container {
    text-align: center;
    font-size: 10px;
    margin-bottom: 30px;
    border-bottom-color: black;
    border-bottom-width: 1px;
    letter-spacing: 0.5px; }
    .box-info-main-container .description-button-container .description-content {
      padding-top: 10px;
      max-height: 200px;
      font-size: 14px;
      font-weight: 400px; }
      .box-info-main-container .description-button-container .description-content.heading {
        color: #5fc3ca; }
  .box-info-main-container .includes-button-container {
    text-align: center;
    font-size: 10px;
    margin-bottom: 30px;
    border-bottom-color: black;
    border-bottom-width: 1px;
    letter-spacing: 1px;
    margin-bottom: 10px; }
    .box-info-main-container .includes-button-container .includes-content {
      padding-top: 10px;
      max-height: 200px;
      font-size: 10px;
      font-weight: 400px; }

.box-attributes-main-container .quantity-field-container {
  text-align: center;
  padding-bottom: 10px; }
  .box-attributes-main-container .quantity-field-container .quantity-title {
    font-size: 10px;
    letter-spacing: 1px;
    padding-bottom: 10px; }
  .box-attributes-main-container .quantity-field-container .quantity-button-field {
    padding-bottom: 10px; }

.box-attributes-main-container .card-selection-title {
  font-size: 10px;
  letter-spacing: 1px;
  padding-top: 10px; }

.box-attributes-main-container .loading-icon {
  margin: auto; }

.box-attributes-main-container .box-selection-container {
  padding-bottom: 10px; }
  .box-attributes-main-container .box-selection-container .box-selection-title {
    font-size: 10px;
    letter-spacing: 1px;
    padding-bottom: 10px; }
  .box-attributes-main-container .box-selection-container .box-selection-summary {
    font-size: 14px;
    letter-spacing: 0.5px;
    padding-bottom: 20px; }
  .box-attributes-main-container .box-selection-container .box-image-selection {
    display: flex;
    justify-content: space-evenly; }
    .box-attributes-main-container .box-selection-container .box-image-selection .box-info {
      height: 211px;
      width: 166px;
      border: 3px solid lightgray; }
      .box-attributes-main-container .box-selection-container .box-image-selection .box-info .box-image {
        height: 166px;
        width: 160px;
        cursor: pointer; }
      .box-attributes-main-container .box-selection-container .box-image-selection .box-info .box-title {
        padding: 10px 0;
        letter-spacing: 1px;
        font-size: 10px; }
    .box-attributes-main-container .box-selection-container .box-image-selection .box-selected {
      border: 3px solid #5fc3ca; }

.box-attributes-main-container .pick-card-container {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 2px dashed #ddd;
  cursor: pointer; }
  .box-attributes-main-container .pick-card-container .pick-card-title {
    font-size: 10px;
    font-weight: 700; }

.box-attributes-main-container .pick-card-container-selected {
  border: 2px solid #5fc3ca;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 84px;
  cursor: pointer;
  display: inline-flex; }
  .box-attributes-main-container .pick-card-container-selected .selected-card-image {
    flex: 1 1;
    width: 80px;
    height: 80px; }
    .box-attributes-main-container .pick-card-container-selected .selected-card-image img {
      width: 80px;
      height: 80px; }
  .box-attributes-main-container .pick-card-container-selected .selected-card-title-change-text {
    flex: 5 1;
    padding: 20px;
    text-align: left; }
    .box-attributes-main-container .pick-card-container-selected .selected-card-title-change-text .selected-card-title {
      padding-bottom: 5px; }
    .box-attributes-main-container .pick-card-container-selected .selected-card-title-change-text .selected-card-change {
      color: lightgray; }

.box-attributes-main-container .cart-button-container {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px solid black;
  background-color: white;
  width: 100%;
  cursor: pointer; }
  .box-attributes-main-container .cart-button-container .cart-button-title {
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 400;
    color: inherit;
    border: none;
    background-color: inherit; }

.box-attributes-main-container .more-buttons-container {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer; }
  .box-attributes-main-container .more-buttons-container .shop-more-button {
    flex: 1 1;
    text-align: center;
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 400;
    color: black;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #ffffff;
    margin-right: 10px;
    border: 1px solid black; }
  .box-attributes-main-container .more-buttons-container .checkout-button {
    flex: 1 1;
    text-align: center;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
    color: black;
    background-color: #ffffff;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: 10px;
    border: 1px solid black;
    letter-spacing: 1px; }

.shop-item-input-wrap {
  display: flex;
  width: 110px;
  align-items: center;
  margin: auto;
  height: 30px;
  border: 1px solid #5fc3ca;
  border-radius: 2px; }

.shop-item-increase-decrease {
  width: 30%;
  cursor: pointer;
  height: 29px;
  vertical-align: middle;
  padding-top: 6px; }

.shop-item-increase-decrease:hover {
  background: #5fc3ca;
  color: white; }

.shop-item-input-wrap input {
  width: 40%;
  border: none;
  text-align: center; }

.shop-item-input-wrap input:focus {
  border: none;
  outline: none; }

.box-attributes-main-container .more-buttons-container .shop-more-button:hover {
  background-color: #5fc3ca;
  color: white;
  cursor: pointer; }

.box-attributes-main-container .more-buttons-container .checkout-button:hover {
  background-color: #5fc3ca;
  color: white;
  cursor: pointer; }

.box-attributes-main-container .cart-button-container:hover {
  background-color: #5fc3ca;
  color: white;
  cursor: pointer; }

@media only screen and (max-width: 90%) {
}

@media only screen and (max-width: 1200px) {
}

/* #Tablet (Landscape)
================================================== */
@media only screen and (max-width: 1200px) {
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .box-attributes-main-container
    .box-selection-container
    .box-image-selection
    .box-info {
    width: 147px;
    height: 186px;
  }
  .box-attributes-main-container
    .box-selection-container
    .box-image-selection
    .box-info
    .box-image {
    width: 141px;
    height: 147px;
  }

  .box-attributes-main-container .box-selection-container .box-image-selection {
    justify-content: space-between;
  }
}
/* #Tablet (Portrait)
================================================== */
@media only screen and (min-width: 768px) and (max-width: 959px) {
  .box-attributes-main-container
    .box-selection-container
    .box-image-selection
    .box-info {
    width: 147px;
    height: 186px;
  }
  .box-attributes-main-container
    .box-selection-container
    .box-image-selection
    .box-info
    .box-image {
    width: 141px;
    height: 147px;
  }

  .box-attributes-main-container .box-selection-container .box-image-selection {
    justify-content: space-between;
  }
}

/*  #Mobile (Portrait)
================================================== */

/* Note: Design for a width of 320px */

@media only screen and (max-width: 767px) {
  .box-attributes-main-container .box-selection-container .box-image-selection {
    justify-content: space-between;
  }

  .box-attributes-main-container
    .box-selection-container
    .box-image-selection
    .box-info {
    width: 150px;
    height: 190px;
  }
  .box-attributes-main-container
    .box-selection-container
    .box-image-selection
    .box-info
    .box-image {
    width: 144px;
    height: 150px;
  }
}

/* #Mobile (Landscape)
================================================== */

/* Note: Design for a width of 480px */

@media only screen and (min-width: 480px) and (max-width: 767px) {
}
/* iPhone 5 (Portrait) */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
}

/* iPhone 6 Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
}

/* iPhone 6 landscape */
@media only screen and (min-device-width: 641px) and (max-device-width: 667px) and (orientation: landscape) {
}

/* iPhone 6+ Portrait */
@media only screen and (min-device-width: 412px) and (max-device-width: 736px) and (orientation: portrait) {
}

/* iPhone 6+ landscape */
@media only screen and (min-device-width: 668px) and (max-device-width: 736px) and (orientation: landscape) {
}

.box-msg-data {
  width: 100%; }

.change-card-button {
  text-align: center; }

.card-image .card-image-selected {
  width: 350px;
  height: 350px; }

.box-msg-data .box-msg-to-from {
  display: flex;
  justify-content: space-between; }

.box-msg-data .card-label {
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  line-height: 1.6;
  color: #000; }

.box-msg-data .box-msg-to-from > div {
  width: 48%;
  display: block; }

.box-msg-data .box-msg-to-from input.box-msg-input {
  border: 1px solid #cccccc;
  border-radius: 0;
  outline: none;
  width: 100%;
  max-width: 100%;
  display: block;
  margin: 8px 0 1em;
  background-color: transparent;
  padding: 8px 10px;
  line-height: 1.42;
  font-size: 1em;
  color: #000;
  font-weight: normal;
  font-family: 'BrandonGrotesque Regular'; }

input {
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  text-align: start;
  -webkit-rtl-ordering: logical;
  cursor: text;
  -writing-mode: horizontal-tb !important;
  text-rendering: auto;
  font-family: 'BrandonGrotesque Regular'; }

.box-msg-data .box-msg-to-from input::-webkit-input-placeholder, .box-msg-data .box-msg-description textarea::-webkit-input-placeholder {
  color: #999999;
  letter-spacing: 1px;
  font-family: 'BrandonGrotesque Regular'; }

.box-msg-data .box-msg-to-from input::-ms-input-placeholder, .box-msg-data .box-msg-description textarea::-ms-input-placeholder {
  color: #999999;
  letter-spacing: 1px;
  font-family: 'BrandonGrotesque Regular'; }

.box-msg-data .box-msg-to-from input::placeholder,
.box-msg-data .box-msg-description textarea::placeholder {
  color: #999999;
  letter-spacing: 1px;
  font-family: 'BrandonGrotesque Regular'; }

.box-msg-data .box-msg-description textarea:focus {
  outline: none !important;
  outline-offset: unset !important; }

.box-msg-data .box-msg-description textarea {
  display: block;
  margin: 10px 0 15px 0;
  margin-top: 10px;
  margin-right: 0px;
  margin-bottom: 15px;
  margin-left: 0px;
  padding: 15px;
  width: 100%;
  height: auto;
  font-size: 14px;
  font-weight: normal;
  color: #000;
  border: solid 1px #ccc;
  box-sizing: border-box;
  max-width: 100%;
  background-color: transparent;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  text-align: start;
  text-rendering: auto;
  -webkit-rtl-ordering: logical;
  -webkit-writing-mode: horizontal-tb !important;
  flex-direction: column;
  resize: auto;
  cursor: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  font-family: 'BrandonGrotesque Regular';
  resize: none; }

.box-msg-data .box-msg-characters {
  margin-top: -10px;
  color: black;
  text-align: right;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.6;
  font-family: 'BrandonGrotesque Regular'; }

.box-msg-data .box-msg-checkbox-container {
  display: -moz-flex;
  display: flex;
  flex-flow: row nowrap;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start;
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  line-height: 1.6;
  color: #000;
  padding-top: 10px;
  font-family: 'BrandonGrotesque Regular'; }

.box-msg-data .box-msg-checkbox-container input {
  box-sizing: border-box;
  -moz-flex: 0 0 auto;
  flex: 0 0 auto;
  position: relative;
  display: inline-block;
  margin: 0 7px 0 0;
  width: 18px;
  height: 18px;
  outline: 0;
  color: #fff;
  border-radius: 3px;
  border: solid 2px #000;
  background: transparent;
  vertical-align: middle;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  line-height: 1.42;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
  font-family: 'BrandonGrotesque Regular'; }

.box-msg-data .box-msg-checkbox-container input::before {
  position: absolute;
  top: 50%;
  left: 50%;
  content: '\F00C';
  font-weight: 900;
  font-size: 10px;
  opacity: 0;
  transform: translate(-50%, -50%); }

.box-msg-data .box-msg-checkbox-container input.checked::before {
  opacity: 1 !important; }

.box-msg-data .box-msg-checkbox-container span {
  -moz-flex: 1 1 auto;
  flex: 1 1 auto;
  display: inline-block;
  margin: 0;
  line-height: 18px;
  font-weight: normal;
  vertical-align: middle;
  font-size: 14px;
  text-align: left;
  font-weight: 600;
  font-family: 'BrandonGrotesque Regular'; }

@media only screen and (max-width: 90%) {
}

@media only screen and (max-width: 1200px) {
}

/* #Tablet (Landscape)
================================================== */
@media only screen and (max-width: 1200px) {
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
}
/* #Tablet (Portrait)
================================================== */
@media only screen and (min-width: 768px) and (max-width: 959px) {
}

/*  #Mobile (Portrait)
================================================== */

/* Note: Design for a width of 320px */

@media only screen and (max-width: 767px) {
  .box-msg-data .box-msg-to-from {
    display: block;
  }

  .box-msg-data .box-msg-to-from > div {
    width: 100%;
  }
}

/* #Mobile (Landscape)
================================================== */

/* Note: Design for a width of 480px */

@media only screen and (min-width: 480px) and (max-width: 767px) {
}
/* iPhone 5 (Portrait) */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
}

/* iPhone 6 Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
}

/* iPhone 6 landscape */
@media only screen and (min-device-width: 641px) and (max-device-width: 667px) and (orientation: landscape) {
}

/* iPhone 6+ Portrait */
@media only screen and (min-device-width: 412px) and (max-device-width: 736px) and (orientation: portrait) {
}

/* iPhone 6+ landscape */
@media only screen and (min-device-width: 668px) and (max-device-width: 736px) and (orientation: landscape) {
}

.card-modal-container {
  width: 1070px;
  display: inline-flex;
  flex-wrap: wrap; }
  .card-modal-container .card-image-component {
    width: 265px;
    height: 265px;
    padding: 5px;
    margin-bottom: 30px;
    cursor: pointer; }
    .card-modal-container .card-image-component .card-image {
      padding: 5px;
      width: 265px;
      height: 265px;
      position: relative; }
    .card-modal-container .card-image-component .card-footer-text {
      text-align: center; }

.card-modal-header .card-modal-title {
  font-size: 24px;
  text-align: center;
  padding: 10px 0; }

.card-modal-header .card-modal-summary {
  font-size: 14px;
  text-align: center;
  padding: 10px 0; }

@media only screen and (max-width: 90%) {
}

@media only screen and (max-width: 1200px) {
}

/* #Tablet (Landscape)
================================================== */
@media only screen and (max-width: 1200px) {
  .card-modal-container {
    width: 720px;
  }

  .card-modal-container .card-image-component {
    width: 180px;
    height: 180px;
    padding: 5px;
    margin-bottom: 30px;
    cursor: pointer;
  }

  .card-modal-container .card-image-component .card-image {
    padding: 5px;
    width: 180px;
    height: 180px;
    position: relative;
  }

  .card-modal-header .card-modal-summary {
    font-size: 10px;
    text-align: center;
    padding: 10px 0;
    width: 65%;
    margin: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
}
/* #Tablet (Portrait)
================================================== */
@media only screen and (min-width: 768px) and (max-width: 959px) {
  .card-modal-header .card-modal-summary {
    font-size: 10px;
    text-align: center;
    padding: 10px 0;
    width: 65%;
    margin: auto;
  }
}

/*  #Mobile (Portrait)
================================================== */

/* Note: Design for a width of 320px */

@media only screen and (max-width: 767px) {
  .card-modal-container {
    width: 300px;
    justify-content: space-between;
  }

  .card-modal-container .card-image-component {
    width: 100px;
    height: 100px;
    padding: 0px;
    margin-bottom: 30px;
    cursor: pointer;
    justify-content: space-between;
    text-align: center;
  }

  .card-modal-container .card-image-component .card-footer-text {
    font-size: 12px;
    letter-spacing: 0;
    text-align: center;
  }
  .card-modal-container .card-image-component .card-image {
    padding: 5px;
    width: 100px;
    height: 100px;
    position: relative;
  }
}

/* #Mobile (Landscape)
================================================== */

/* Note: Design for a width of 480px */

@media only screen and (min-width: 480px) and (max-width: 767px) {
}
/* iPhone 5 (Portrait) */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
}

/* iPhone 6 Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
}

/* iPhone 6 landscape */
@media only screen and (min-device-width: 641px) and (max-device-width: 667px) and (orientation: landscape) {
}

/* iPhone 6+ Portrait */
@media only screen and (min-device-width: 412px) and (max-device-width: 736px) and (orientation: portrait) {
}

/* iPhone 6+ landscape */
@media only screen and (min-device-width: 668px) and (max-device-width: 736px) and (orientation: landscape) {
}

.info-modal-container {
  width: 346px;
  height: 300px;
  background-image: url("https://cdn.shopify.com/s/files/1/0445/1313/2702/files/pop_up.png?v=1603414518");
  background-size: cover;
  padding: 30px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 18px; }
  .info-modal-container .info-modal-image {
    width: 346px;
    height: 300px; }
  .info-modal-container .info-modal-title-container {
    margin-top: 40px;
    padding: 0 36px;
    line-height: 24px; }
  .info-modal-container .info-modal-title {
    display: block;
    padding: 0px 5px 0 5px;
    font-size: 20px;
    color: #000;
    text-align: left; }
  .info-modal-container .info-modal-info {
    display: block;
    padding: 20px 5px;
    font-size: 14px;
    color: #000;
    text-align: left; }
  .info-modal-container .info-modal-button-container {
    margin-top: 85px;
    color: white;
    height: 50px;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1.2px; }
    .info-modal-container .info-modal-button-container .info-button {
      padding: 11px;
      cursor: pointer; }
  .info-modal-container .info-modal-button {
    display: block;
    padding: 20px 5px;
    border-top: solid 1px lightgray;
    text-align: right; }
    .info-modal-container .info-modal-button .info-modal-button-text {
      cursor: pointer;
      padding: 18px 20px;
      background-color: #5fc3ca;
      color: white;
      border: none;
      font-size: 10px;
      letter-spacing: 1px; }

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.4) !important; }

@media only screen and (max-width: 90%) {
}

@media only screen and (max-width: 1200px) {
}

/* #Tablet (Landscape)
================================================== */
@media only screen and (max-width: 1200px) {
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
}
/* #Tablet (Portrait)
================================================== */
@media only screen and (min-width: 768px) and (max-width: 959px) {
}

/*  #Mobile (Portrait)
================================================== */

/* Note: Design for a width of 320px */

@media only screen and (max-width: 767px) {
  .ReactModalPortal .ReactModal__Content.ReactModal__Content--after-open{
    top: 50%!important;
  }

  .info-modal-container {
    width: 230.5px;
    height: 200px;
    font-size: 14px;
    padding: 10px;
  }
  .info-modal-image{
    width: 230.5px;
    height: 200px;
  }
  .info-modal-container .info-modal-title-container{
    margin-top: 40px;
    padding: 0 24px;
  }
  
  .info-modal-container .info-modal-button-container{
    font-size: 12px;
    margin-top: 35px;
    color: white;
  }
  .info-modal-container .info-modal-button-container .info-button{
    padding: 0;
  }
}

/* #Mobile (Landscape)
================================================== */

/* Note: Design for a width of 480px */

@media only screen and (min-width: 480px) and (max-width: 767px) {
}
/* iPhone 5 (Portrait) */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
}

/* iPhone 6 Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
}

/* iPhone 6 landscape */
@media only screen and (min-device-width: 641px) and (max-device-width: 667px) and (orientation: landscape) {
}

/* iPhone 6+ Portrait */
@media only screen and (min-device-width: 412px) and (max-device-width: 736px) and (orientation: portrait) {
}

/* iPhone 6+ landscape */
@media only screen and (min-device-width: 668px) and (max-device-width: 736px) and (orientation: landscape) {
}

.corporate-gifting-main-container {
  width: 100%;
  margin: 0px auto;
  padding: 80px 30px;
  font-family: 'BrandonGrotesque Regular'; }
  .corporate-gifting-main-container .wrapper {
    text-align: center;
    margin: auto;
    width: 320px; }
    .corporate-gifting-main-container .wrapper .corporate-gifting-title-container {
      margin-bottom: 10px;
      font-size: 24px;
      max-width: 500px; }
    .corporate-gifting-main-container .wrapper .corporate-gifting-title-text {
      font-size: 10px; }
    .corporate-gifting-main-container .wrapper .corporate-gifting-title-summary {
      margin-top: 10px;
      margin-bottom: 40px;
      font-size: 10px; }
    .corporate-gifting-main-container .wrapper .corporate-gifting-field .phone-input,
    .corporate-gifting-main-container .wrapper .corporate-gifting-field .email-input,
    .corporate-gifting-main-container .wrapper .corporate-gifting-field .last-name-input,
    .corporate-gifting-main-container .wrapper .corporate-gifting-field .first-name-input,
    .corporate-gifting-main-container .wrapper .corporate-gifting-field .company-input {
      border: 1px solid #cccccc;
      border-radius: 0;
      outline: none;
      width: 100%;
      max-width: 100%;
      display: block;
      margin: 8px 0 1em;
      background-color: transparent;
      padding: 8px 10px;
      line-height: 1.42;
      font-size: 1em;
      color: #000;
      font-weight: normal; }
    .corporate-gifting-main-container .wrapper .corporate-gifting-field .message-input {
      border: 1px solid #cccccc;
      border-radius: 0;
      outline: none;
      width: 100%;
      max-width: 100%;
      display: block;
      margin: 8px 0 1em;
      background-color: transparent;
      padding: 8px 10px;
      line-height: 1.42;
      font-size: 14px;
      color: #000;
      font-weight: normal;
      resize: vertical;
      font-family: 'BrandonGrotesque Regular';
      min-height: 100px; }
    .corporate-gifting-main-container .wrapper .corporate-gifting-thanks,
    .corporate-gifting-main-container .wrapper .corporate-gifting-submit {
      border: 1px solid #cccccc;
      background-color: white;
      color: black;
      cursor: pointer;
      margin-bottom: 20px; }
      .corporate-gifting-main-container .wrapper .corporate-gifting-thanks .submit-button-text,
      .corporate-gifting-main-container .wrapper .corporate-gifting-submit .submit-button-text {
        padding: 15px 0px;
        font-size: 10px;
        letter-spacing: 2px;
        text-transform: uppercase; }
      .corporate-gifting-main-container .wrapper .corporate-gifting-thanks.successful,
      .corporate-gifting-main-container .wrapper .corporate-gifting-submit.successful {
        background-color: #5fc3ca;
        color: white; }
    .corporate-gifting-main-container .wrapper .corporate-gifting-submit:hover {
      background-color: #5fc3ca;
      color: white; }

@media only screen and (max-width: 90%) {
}

@media only screen and (max-width: 1200px) {
}

/* #Tablet (Landscape)
================================================== */
@media only screen and (max-width: 1200px) {
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
}
/* #Tablet (Portrait)
================================================== */
@media only screen and (min-width: 768px) and (max-width: 959px) {
}

/*  #Mobile (Portrait)
================================================== */

/* Note: Design for a width of 320px */

@media only screen and (max-width: 767px) {
    .corporate-gifting-main-container{
        width: 320px;
    }

    .corporate-gifting-main-container .wrapper{
        margin: auto;
        width: 100%;
    }
}

/* #Mobile (Landscape)
================================================== */

/* Note: Design for a width of 480px */

@media only screen and (min-width: 480px) and (max-width: 767px) {
}
/* iPhone 5 (Portrait) */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
}

/* iPhone 6 Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
}

/* iPhone 6 landscape */
@media only screen and (min-device-width: 641px) and (max-device-width: 667px) and (orientation: landscape) {
}

/* iPhone 6+ Portrait */
@media only screen and (min-device-width: 412px) and (max-device-width: 736px) and (orientation: portrait) {
}

/* iPhone 6+ landscape */
@media only screen and (min-device-width: 668px) and (max-device-width: 736px) and (orientation: landscape) {
}

.nav-main-container {
  display: inline-flex;
  justify-content: space-between;
  height: 60px !important;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #5fc3ca; }
  .nav-main-container .salad-days-title-container {
    width: 100px; }
  .nav-main-container .nav-left-sections {
    display: inline-flex; }
    .nav-main-container .nav-left-sections .salad-days-image-section {
      padding-top: 20px;
      margin-right: 40px;
      margin-left: 20px; }
      .nav-main-container .nav-left-sections .salad-days-image-section .salad-days-logo {
        height: 20px;
        width: 144px; }
    .nav-main-container .nav-left-sections .salad-days-sections {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 10px;
      letter-spacing: 2px;
      text-transform: uppercase;
      padding-top: 24px;
      padding-bottom: 24px; }
      .nav-main-container .nav-left-sections .salad-days-sections .section-text:hover {
        color: #5fc3ca;
        cursor: pointer; }
      .nav-main-container .nav-left-sections .salad-days-sections .section-text.highlight {
        color: #5fc3ca; }
  .nav-main-container .nav-right-sections {
    display: inline-flex; }
    .nav-main-container .nav-right-sections .salad-days-cart-section {
      padding-left: 15px;
      padding-right: 15px;
      margin-right: 30px;
      font-size: 10px;
      letter-spacing: 2px;
      padding-top: 24px;
      padding-bottom: 24px;
      float: right;
      display: inline-flex; }
      .nav-main-container .nav-right-sections .salad-days-cart-section:hover {
        color: #5fc3ca;
        cursor: pointer; }
      .nav-main-container .nav-right-sections .salad-days-cart-section .cart-icon-container {
        display: flex; }
        .nav-main-container .nav-right-sections .salad-days-cart-section .cart-icon-container .cart-icon {
          width: 25px;
          height: 25px;
          margin: -5px 10px; }
        .nav-main-container .nav-right-sections .salad-days-cart-section .cart-icon-container .cart-count {
          position: relative;
          margin-left: -15px;
          margin-top: -15px;
          top: 0;
          right: 0;
          font-size: 8px;
          width: auto;
          height: 16px;
          width: 16px;
          font-weight: bold;
          color: white;
          background: #5fc3ca;
          border: none;
          font-family: 'BrandonGrotesque Regular';
          text-align: center;
          border-radius: 10px;
          line-height: 18px;
          padding-left: 2px;
          letter-spacing: 0;
          text-align: center; }
      .nav-main-container .nav-right-sections .salad-days-cart-section .section-text {
        padding-right: 5px; }
      .nav-main-container .nav-right-sections .salad-days-cart-section .section-price {
        letter-spacing: 1px; }
        .nav-main-container .nav-right-sections .salad-days-cart-section .section-price.highlight {
          color: #5fc3ca; }
      .nav-main-container .nav-right-sections .salad-days-cart-section .salad-days-cart-section {
        display: flex; }

.mobile {
  display: none; }

.nav-main-container .nav-main-wrap {
  width: 100%; }

.nav-main-container .nav-main-wrap .nav-right-sections.desktop {
  float: right; }

@media only screen and (max-width: 90%) {
}

@media only screen and (max-width: 1200px) {
}

/* #Tablet (Landscape)
================================================== */
@media only screen and (max-width: 1200px) {
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
}
/* #Tablet (Portrait)
================================================== */
@media only screen and (min-width: 768px) and (max-width: 959px) {
}

/*  #Mobile (Portrait)
================================================== */

/* Note: Design for a width of 320px */

@media only screen and (max-width: 767px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none !important;
  }
  .nav-main-container {
    display: block;
    height: 90px !important;
  }
  .nav-main-container .section-text {
    font-size: 10px;
  }
  .nav-main-container .nav-right-sections .salad-days-cart-section {
    margin-right: 0px;
  }

  .nav-main-container .nav-wrap.mobile .nav-wrap-mobile-container {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
  }

  .cart-icon-container {
    display: flex;
  }
  .cart-icon-container .cart-icon {
    width: 20px;
    height: 20px;
    margin: -5px 10px;
  }

  .cart-icon-container .cart-count {
    position: relative;
    margin-left: -15px;
    margin-top: -15px;
    top: 0;
    right: 0;
    font-size: 8px;
    width: auto;
    height: 14px;
    width: 14px;
    font-weight: bold;
    color: white;
    background: #5fc3ca;
    border: none;
    font-family: 'BrandonGrotesque Regular';
    text-align: center;
    border-radius: 10px;
    line-height: 18px;
    padding-left: 2px;
    letter-spacing: 0;
    text-align: center;
  }

  .cart-icon-container .cart-count .cart-count-text {
    margin-bottom: 1px;
  }

  .section-price {
    margin-top: 5px;
    font-size: 8px;
  }
}

/* #Mobile (Landscape)
================================================== */

/* Note: Design for a width of 480px */

@media only screen and (min-width: 480px) and (max-width: 767px) {
}
/* iPhone 5 (Portrait) */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
}

/* iPhone 6 Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
}

/* iPhone 6 landscape */
@media only screen and (min-device-width: 641px) and (max-device-width: 667px) and (orientation: landscape) {
}

/* iPhone 6+ Portrait */
@media only screen and (min-device-width: 412px) and (max-device-width: 736px) and (orientation: portrait) {
}

/* iPhone 6+ landscape */
@media only screen and (min-device-width: 668px) and (max-device-width: 736px) and (orientation: landscape) {
}

