@media only screen and (max-width: 90%) {
}

@media only screen and (max-width: 1200px) {
}

/* #Tablet (Landscape)
================================================== */
@media only screen and (max-width: 1200px) {
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
}
/* #Tablet (Portrait)
================================================== */
@media only screen and (min-width: 768px) and (max-width: 959px) {
}

/*  #Mobile (Portrait)
================================================== */

/* Note: Design for a width of 320px */

@media only screen and (max-width: 767px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none !important;
  }
  .nav-main-container {
    display: block;
    height: 90px !important;
  }
  .nav-main-container .section-text {
    font-size: 10px;
  }
  .nav-main-container .nav-right-sections .salad-days-cart-section {
    margin-right: 0px;
  }

  .nav-main-container .nav-wrap.mobile .nav-wrap-mobile-container {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
  }

  .cart-icon-container {
    display: flex;
  }
  .cart-icon-container .cart-icon {
    width: 20px;
    height: 20px;
    margin: -5px 10px;
  }

  .cart-icon-container .cart-count {
    position: relative;
    margin-left: -15px;
    margin-top: -15px;
    top: 0;
    right: 0;
    font-size: 8px;
    width: auto;
    height: 14px;
    width: 14px;
    font-weight: bold;
    color: white;
    background: #5fc3ca;
    border: none;
    font-family: 'BrandonGrotesque Regular';
    text-align: center;
    border-radius: 10px;
    line-height: 18px;
    padding-left: 2px;
    letter-spacing: 0;
    text-align: center;
  }

  .cart-icon-container .cart-count .cart-count-text {
    margin-bottom: 1px;
  }

  .section-price {
    margin-top: 5px;
    font-size: 8px;
  }
}

/* #Mobile (Landscape)
================================================== */

/* Note: Design for a width of 480px */

@media only screen and (min-width: 480px) and (max-width: 767px) {
}
/* iPhone 5 (Portrait) */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
}

/* iPhone 6 Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
}

/* iPhone 6 landscape */
@media only screen and (min-device-width: 641px) and (max-device-width: 667px) and (orientation: landscape) {
}

/* iPhone 6+ Portrait */
@media only screen and (min-device-width: 412px) and (max-device-width: 736px) and (orientation: portrait) {
}

/* iPhone 6+ landscape */
@media only screen and (min-device-width: 668px) and (max-device-width: 736px) and (orientation: landscape) {
}
