.cart-item-container {
  display: flex;
  position: relative;
  padding-top: 30px;
  margin-top: 30px;
  padding-bottom: 20px;
  .cart-item-image-contents {
    flex: 2;
    display: flex;
    position: relative;
    .item-image {
      flex: 2;
      vertical-align: middle;

      .box-item-image {
        max-width: 100px;
        max-height: 100px;
        margin-top: 40px;
      }
    }
    .cart-item-contents {
      flex: 3;
      vertical-align: middle;
      display: table-cell;
      float: none;
      .item-title-container {
        .item-title {
          font-size: 24px;
          text-transform: uppercase;
          padding-bottom: 5px;
        }
        .item-title-subtext {
          font-size: 10px;
          font-weight: 300;
          padding-bottom: 20px;
        }
      }
      .item-card {
        font-weight: 200;
      }
      .item-contents {
        font-weight: 200;
      }
      .item-remove {
        font-size: 9px;
        text-transform: uppercase;
        color: grey;
        padding-top: 10px;
        cursor: pointer;
      }
    }
  }
  .cart-item-price {
    flex: 1;
    text-align: center;
    margin: auto 0px;
  }
  .cart-item-quantity {
    flex: 1;
    text-align: center;
    margin: auto 0px;
    .loading-icon {
      width: 110px;
      align-items: center;
      margin: auto;
      height: 30px;
    }
  }
  .cart-item-subtotal {
    flex: 1;
    text-align: center;
    margin: auto 0px;
  }
}

.cart-item-input-wrap {
  display: flex;
  width: 110px;
  align-items: center;
  margin: auto;
  height: 30px;
  border: 1px solid black;
  border-radius: 2px;
}

.cart-item-increase-decrease {
  width: 30%;
  cursor: pointer;
  height: 29px;
  vertical-align: middle;
  padding-top: 6px;
}

.cart-item-increase-decrease:hover {
  background: #5fc3ca;
  color: white;
  color: white;
}

.cart-item-input-wrap input {
  width: 40%;
  border: none;
  text-align: center;
}

.cart-item-input-wrap input:focus {
  border: none;
  outline: none;
}
