@media only screen and (max-width: 90%) {
}

@media only screen and (max-width: 1200px) {
}

/* #Tablet (Landscape)
================================================== */
@media only screen and (max-width: 1200px) {
  .shop-main-container{
    max-width: 920px;
    margin: auto;
  }

  .shop-main-container .shop-items-container {
    display: flex;
    width: 820px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
  }

  .shop-main-container .shop-header-subtitle{
    max-width: 90%;
    width: 820px;
    padding: 0;
    margin: 0 auto 10px auto;
  }

  .shop-main-container .shop-header-subtitle-summary{
    max-width: 90%;
    width: 820px;
    padding: 0;
    margin: 0 auto 30px auto;
  }

  .shop-main-container .shop-header-container{
    max-width: 90%;
    width: 820px;
    padding: 0;
    margin: 70px auto 20px auto;
  }

  .shop-main-container .shop-items-container .product-container{
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
}
/* #Tablet (Portrait)
================================================== */
@media only screen and (min-width: 768px) and (max-width: 959px) {
  .container {
    position: relative;
    width: 220px;
    height: 220px;
    cursor: pointer;
  }

  
  img.product-image {
    position: absolute;
    width: 220px;
    height: 220px;
    left: 0;
  }

  .shop-main-container{
    max-width: 920px;
    margin: auto;
  }

  .shop-main-container .shop-items-container .product-container{
    padding: 0;
  }
  
  .shop-main-container .shop-header-subtitle{
    max-width: 90%;
    width: 820px;
    padding: 0;
    margin: 0 auto 10px auto;
  }

  .shop-main-container .shop-header-subtitle-summary{
    max-width: 90%;
    width: 820px;
    padding: 0;
    margin: 0 auto 30px auto;
  }

  .shop-main-container .shop-items-container {
    display: flex;
    max-width: 90%;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
  }

  
  .shop-main-container .shop-header-container{
    max-width: 90%;
    padding: 0;
    margin: 70px auto 20px auto;
  }

}

/*  #Mobile (Portrait)
================================================== */

/* Note: Design for a width of 320px */

@media only screen and (max-width: 767px) {
  .container {
    position: relative;
    width: 150px;
    height: 150px;
    cursor: pointer;
  }

  img.product-image {
    position: absolute;
    width: 150px;
    height: 150px;
    left: 0;
  }

  .product-container {
    padding-left: 0px;
    margin-bottom: 0px;
  }

  .shop-main-container .shop-items-container {
    display: flex;
    width: 320px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
  }

  .shop-main-container .shop-header-container{
    max-width: unset;
    width: 320px;
    margin: 50px auto 15px auto;
  }

  .shop-main-container .shop-header-subtitle{
    max-width: unset;
    width: 320px;
    padding: 0;
    margin: 0 auto 10px auto;
  }

  .shop-main-container .shop-header-subtitle-summary{
    max-width: unset;
    width: 320px;
    padding: 0;
    margin: 0 auto 30px auto;
  }
}

/* #Mobile (Landscape)
================================================== */

/* Note: Design for a width of 480px */

@media only screen and (min-width: 480px) and (max-width: 767px) {
}
/* iPhone 5 (Portrait) */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
}

/* iPhone 6 Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
}

/* iPhone 6 landscape */
@media only screen and (min-device-width: 641px) and (max-device-width: 667px) and (orientation: landscape) {
}

/* iPhone 6+ Portrait */
@media only screen and (min-device-width: 412px) and (max-device-width: 736px) and (orientation: portrait) {
}

/* iPhone 6+ landscape */
@media only screen and (min-device-width: 668px) and (max-device-width: 736px) and (orientation: landscape) {
}
