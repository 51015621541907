.box-info-main-container {
  margin-top: 20px;
  margin-bottom: 20px;
  .description-button-container {
    text-align: center;
    font-size: 10px;
    margin-bottom: 30px;
    border-bottom-color: black;
    border-bottom-width: 1px;
    letter-spacing: 0.5px;
    .description-content {
      padding-top: 10px;
      max-height: 200px;
      font-size: 14px;
      font-weight: 400px;
      &.heading {
        color: #5fc3ca;
      }
    }
  }
  .includes-button-container {
    text-align: center;
    font-size: 10px;
    margin-bottom: 30px;
    border-bottom-color: black;
    border-bottom-width: 1px;
    letter-spacing: 1px;
    margin-bottom: 10px;
    .includes-content {
      padding-top: 10px;
      max-height: 200px;
      font-size: 10px;
      font-weight: 400px;
    }
  }
}
