@media only screen and (max-width: 90%) {
}

@media only screen and (max-width: 1200px) {
}

/* #Tablet (Landscape)
================================================== */
@media only screen and (max-width: 1200px) {
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
}
/* #Tablet (Portrait)
================================================== */
@media only screen and (min-width: 768px) and (max-width: 959px) {
}

/*  #Mobile (Portrait)
================================================== */

/* Note: Design for a width of 320px */

@media only screen and (max-width: 767px) {
    .mobile .cart-item-details-wrap{
        display: flex;
        justify-content: space-between;
        padding: 10px;
    }

    .mobile .cart-item-details-wrap .cart-item-price-container,
    .mobile .cart-item-details-wrap .cart-item-subtotal-container{
        width: 60px;
    }
    .mobile .cart-item-details-wrap .cart-item-price-container > div,
    .mobile .cart-item-details-wrap .cart-item-subtotal-container > div{
        text-align: left;
    }

    .mobile .cart-item-details-wrap .primary{
        height: 20px;
    }

    .mobile .cart-item-details-wrap .secondary{
        height: 30px;
        vertical-align: middle;
        margin: 5px auto;
    }
    
    .mobile .cart-item-details-wrap .cart-item--value{
        margin-top: 10px;
    }

    .cart-item-container.mobile .cart-item-image-content-wrap{
        padding: 10px;
    }

    .cart-main-container .cart-header-container .cart-title{
        padding: 20px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .cart-item-container{
        margin-top: 0px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    
}

/* #Mobile (Landscape)
================================================== */

/* Note: Design for a width of 480px */

@media only screen and (min-width: 480px) and (max-width: 767px) {
}
/* iPhone 5 (Portrait) */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
}

/* iPhone 6 Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
}

/* iPhone 6 landscape */
@media only screen and (min-device-width: 641px) and (max-device-width: 667px) and (orientation: landscape) {
}

/* iPhone 6+ Portrait */
@media only screen and (min-device-width: 412px) and (max-device-width: 736px) and (orientation: portrait) {
}

/* iPhone 6+ landscape */
@media only screen and (min-device-width: 668px) and (max-device-width: 736px) and (orientation: landscape) {
}
