.header {
  font-size: 18px;
  font-weight: 400;
  color: rgb(57, 132, 201);
  text-align: center;
}

.main-title {
  padding-bottom: 10px;
}

@font-face {
  font-family: 'BrandonGrotesque Regular';
  src: local('BrandonGrotesque Regular'),
    url('./fonts/BrandonGrotesque-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Jane Austen';
  src: local('Jane Austen'), url('./fonts/JaneAusten V2.ttf') format('truetype');
}

body {
  font-family: 'BrandonGrotesque Regular';
  margin: 0px;
}

#root {
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}
