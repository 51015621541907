.corporate-gifting-main-container {
  width: 100%;
  margin: 0px auto;
  padding: 80px 30px;
  font-family: 'BrandonGrotesque Regular';
  .wrapper {
    text-align: center;
    margin: auto;
    width: 320px;
    .corporate-gifting-title-container {
      margin-bottom: 10px;
      font-size: 24px;
      max-width: 500px;
    }
    .corporate-gifting-title-text {
      font-size: 10px;
    }
    .corporate-gifting-title-summary {
      margin-top: 10px;
      margin-bottom: 40px;
      font-size: 10px;
    }
    .corporate-gifting-field {
      .phone-input,
      .email-input,
      .last-name-input,
      .first-name-input,
      .company-input {
        border: 1px solid #cccccc;
        border-radius: 0;
        outline: none;
        width: 100%;
        max-width: 100%;
        display: block;
        margin: 8px 0 1em;
        background-color: transparent;
        padding: 8px 10px;
        line-height: 1.42;
        font-size: 1em;
        color: #000;
        font-weight: normal;
      }
      .message-input {
        border: 1px solid #cccccc;
        border-radius: 0;
        outline: none;
        width: 100%;
        max-width: 100%;
        display: block;
        margin: 8px 0 1em;
        background-color: transparent;
        padding: 8px 10px;
        line-height: 1.42;
        font-size: 14px;
        color: #000;
        font-weight: normal;
        resize: vertical;
        font-family: 'BrandonGrotesque Regular';
        min-height: 100px;
      }
    }
    .corporate-gifting-thanks,
    .corporate-gifting-submit {
      border: 1px solid #cccccc;
      background-color: white;
      color: black;
      cursor: pointer;
      margin-bottom: 20px;
      .submit-button-text {
        padding: 15px 0px;
        font-size: 10px;
        letter-spacing: 2px;
        text-transform: uppercase;
      }

      &.successful {
        background-color: #5fc3ca;
        color: white;
      }
    }
    .corporate-gifting-submit:hover {
      background-color: #5fc3ca;
      color: white;
    }
  }
}
