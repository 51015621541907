.nav-main-container {
  display: inline-flex;
  justify-content: space-between;
  height: 60px !important;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #5fc3ca;
  .salad-days-title-container {
    width: 100px;
  }
  .nav-left-sections {
    display: inline-flex;
    .salad-days-image-section {
      padding-top: 20px;
      margin-right: 40px;
      margin-left: 20px;
      .salad-days-logo {
        height: 20px;
        width: 144px;
      }
    }
    .salad-days-sections {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 10px;
      letter-spacing: 2px;
      text-transform: uppercase;
      padding-top: 24px;
      padding-bottom: 24px;
      .section-text {
        &:hover {
          color: #5fc3ca;
          cursor: pointer;
        }
        &.highlight {
          color: #5fc3ca;
        }
      }
    }
  }
  .nav-right-sections {
    display: inline-flex;
    .salad-days-cart-section {
      padding-left: 15px;
      padding-right: 15px;
      margin-right: 30px;
      font-size: 10px;
      letter-spacing: 2px;
      padding-top: 24px;
      padding-bottom: 24px;
      float: right;
      display: inline-flex;
      &:hover {
        color: #5fc3ca;
        cursor: pointer;
      }
      .cart-icon-container {
        display: flex;
        .cart-icon {
          width: 25px;
          height: 25px;
          margin: -5px 10px;
        }
        .cart-count {
          position: relative;
          margin-left: -15px;
          margin-top: -15px;
          top: 0;
          right: 0;
          font-size: 8px;
          width: auto;
          height: 16px;
          width: 16px;
          font-weight: bold;
          color: white;
          background: #5fc3ca;
          border: none;
          font-family: 'BrandonGrotesque Regular';
          text-align: center;
          border-radius: 10px;
          line-height: 18px;
          padding-left: 2px;
          letter-spacing: 0;
          text-align: center;
        }
      }
      .section-text {
        padding-right: 5px;
      }
      .section-price {
        letter-spacing: 1px;
        &.highlight {
          color: #5fc3ca;
        }
      }
      .salad-days-cart-section {
        display: flex;
      }
    }
  }
}

.mobile {
  display: none;
}

.nav-main-container .nav-main-wrap {
  width: 100%;
}

.nav-main-container .nav-main-wrap .nav-right-sections.desktop {
  float: right;
}
