.container {
  position: relative;
  width: 257px;
  height: 257px;
  cursor: pointer;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

.container:hover .overlay {
  display: block;
}

.product-container {
  padding-left: 30px;
  margin-bottom: 30px;
}

img.product-image {
  position: absolute;
  width: 257px;
  height: 257px;
  left: 0;
}

.product-details {
  padding-top: 15px;
  padding-bottom: 15px;
}

.button .text {
  padding: 12px 40px;
  text-align: center;
  color: black;
  border: solid 1px black;
  background-color: white;
  z-index: 1;
  text-decoration: none;
}

.container:hover .button {
  opacity: 1;
}

.title {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 10px;
  text-align: left;
  letter-spacing: 1px;
}

.price {
  margin-bottom: 15px;
  font-size: 10px;
  text-align: left;
}

.actual-price {
  text-align: left;
  margin-bottom: 15px;
  font-size: 10px;
  color: #5fc3ca;
  padding-left: 5px;
  text-decoration: line-through;
}

.compare-price {
  display: inline-flex;
}
